.sminv-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
    height: calc(100vh - 65px);
}

.file-border {
    border-style: dashed;
    border-width: 1px;
    border-color: #999;
    height: auto;
    background-color: #e1e1e1;
    text-align: center;
}

.file-border i {
    font-size: 50px;
    color: #999;
}

.file-border label {
    display: inline-block;
    background-color: none;
    color: #2d6aa0;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 0.5rem;
    font-weight: 600;
}

@media only screen and (max-width: 766px) {
    .sminv-container {
        width: 100%;
    }
}