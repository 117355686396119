.rm-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
}

.filterArea {
    display: flex;
    justify-content: end;
    gap: 1rem;
}

@media only screen and (max-width: 766px) {
    .rm-container {
        width: 100%;
    }

    .filterArea {
        flex-direction: column;
    }
}

.rm-main {
    height: calc(100vh - 165px);
    overflow: auto
}

.main-rmPanel {
    background-color: #eeeeee;
    padding: 10px 20px;
    overflow: auto;
    height: calc(100vh - 60px);
    width: 100%
}

.search-input {
    background-color: #fff;
    border: none;
    border-bottom: 1px solid #cee8ff;
    width: 70%;
    color: #2d6aa0;
}

.role-card {
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px 15px;
    border: 1px solid #eee;
    border-radius: 15px;
    height: fit-content;
}

.role-decsription {
    text-align: justify;
    margin-left: 15px;
    margin-right: 15px;
}

.role-table {
    height: 250px;
}

.function-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #eee;
}

.function-container {
    height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
}

.function-container-asset {
    overflow-y: auto;
    overflow-x: hidden;
}

a:hover {
    text-decoration: none;
}

.form-cards {
    border: 1px solid #eee;
    padding: 25px;
}

.custom-label {
    font-size: 10px !important;
}

/* custom scroll bar */

.main-rmPanel::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.main-rmPanel::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.main-rmPanel::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.rm-main::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.rm-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rm-main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.function-container::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.function-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.function-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* admin management */

.am-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
}

.am-main {
    height: calc(100vh - 140px);
    overflow: hidden
}

.main-amPanel {
    background-color: #eeeeee;
    padding: 10px 20px;
    overflow: auto;
    height: calc(100vh - 65px);
    width: 100%
}

.am-role-table {
    height: calc(100vh - 150px);
    margin-left: 20px;
    margin-right: 40px;
    width: 97%;
}

.deleted-role-table {
    height: calc(100vh - 110px);
    margin-left: 20px;
    margin-right: 40px;
    width: 97%;
}

/* top search bar */

.top-search {
    width: 60%;
    border-radius: 5px;
}

@media only screen and (max-width: 766px) {
    .am-container {
        width: 100%;
    }

    .am-role-table {
        width: 90%;
        height: calc(100vh - 160px);
    }

    .deleted-role-table {
        width: 90%;
        height: calc(100vh - 150px);
    }

    .top-search {
        width: 100%;
    }
}



/* custom scroll bar */

.role-table::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.role-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.role-table::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}