body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --primary: #2d6aa0;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.btn-primary {
  background-color: var(--primary) !important;
}

.btn-custom {
  padding: 10px 20px;
}

.text-custom-grey {
  color: #A8A8A8;
  font-weight: 500;
}

.text-custom-greyDark {
  color: #5A5858;
  font-weight: 500;
}

.text-custom-greyLight {
  color: #E1E1E1;
  font-weight: 500;
}

.break-text {
  word-wrap: break-word;
  word-break: break-all;
}

.custom-link:hover {
  text-decoration: none !important;
}

/* gloabl font size */

.f-22 {
  font-size: 24px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-9 {
  font-size: 9px !important;
}

.MuiInputBase-input {
  margin-top: 10px !important;
}

.MuiFormControl-root {
  margin-right: 10px !important;
}

.MuiTableCellEllipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline !important;
  margin: 0;
}

.MuiDataGrid-root .MuiDataGrid-viewport {
  overflow: unset !important;
}

.curPoint {
  cursor: pointer;
}

.modal-width {
  width: auto !important;
  margin: 0 20px !important;
  max-width: unset !important;
}

/* ------------------------loader----------------------------------- */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tableBreakWord {
  white-space: normal;
  word-break: break-all;
  display: block;
}

.clientRemarkWidth {
  width: 30% !important;
}

.dashCard {
  /* width: 100%; */
  height: 100%;
  /* height: 17rem; */
  border: 1px solid #F9FBFB;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat', sans-serif;
  margin: 0 0.5rem !important;
  font-size: 0.9rem !important;
  padding: 0.9rem !important;
}

.buyButton {
  background-color: rgba(25, 210, 31, 0.3);
  color: #19D21F;
  border-radius: 5px;
  width: max-content !important;
  padding: 0.25rem 0.5rem !important;
}

.sellButton {
  background-color: rgb(243 136 94 / 30%);
  color: #d21919;
  border-radius: 5px;
  width: max-content !important;
  padding: 0.25rem 0.5rem !important;
}

.orderDetailColWidth {
  width: 40% !important;
}

.containerW {
  padding-right: 15px;
  padding-left: 15px;
  width: 100% !important;
}


/* accordion------------------------------------------------------ */
.accordion {
  margin-bottom: 0.5rem;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #bec1c1;
  text-transform: capitalize;
}

.accordion-collapse {
  padding: 0 0.25rem;
  background: #b3b3b3;
}

.accordion-title,
.accordion-content {
  padding: 0.5rem;
}

.accordion-content {
  background-color: #e1e1e1;
}

.priceSlab {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--grey);
  margin-top: 0.5rem;
}

.tooltipBox {
  z-index: 999;
  position: absolute;
  background-color: var(--white);
  padding: 0;
  border-radius: 0.5rem;
  display: none;
  text-align: left;
  width: max-content;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.tooltipBox.card {
  padding: 0 !important;
}

.tooltipBox>div:first-child {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  margin: 0 0 0.5rem 0;
  width: auto;
  gap: 1rem;
}

.tooltipBox>div {
  padding: 0 0.5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}