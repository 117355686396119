/* Left Pane Container */

.leftbar-container {
    margin-top: 57px;
    width: 20%;
    border-right: 1px solid #999;
    height: calc(100vh - 65px);
    overflow: auto;
    background-color: #fff;
}

/* SIDEBAR */

.sidebar-container {
    height: 100%;
}

/* custom scroll bar */

.side-nav-company-table::-webkit-scrollbar {
    width: 0.7em;
}

.side-nav-company-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.side-nav-company-table::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.menu {
    margin: 20px 0px 0px 20px;
    width: 100%;
}

.sub-menu a {
    color: #5a5858 !important;
    text-decoration: none;
}

.active {
    background-color: #d7ecff;
    border-radius: 20px;
    padding: 10px 15px;
    margin: 10px 10px;
    color: #2d6aa0 !important;
}

.sub-menu a:hover {
    color: #2d6aa0 !important;
}

@media only screen and (max-width: 992px) {
    .leftbar-container {
        width: 50% !important;
        position: absolute !important;
        z-index: 9999 !important;
    }
}

@media only screen and (max-width: 766px) {
    .leftbar-container {
        width: 90% !important;
    }
}