.nm-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
}

.nm-main {
    height: calc(100vh - 165px);
    overflow: auto
}

.nm-role-table {
    height: calc(100vh - 140px);
    margin-left: 20px;
    margin-right: 40px;
    width: 97%;
}


/* template customizations */

.nm-template {
    height: calc(100vh - 200px);
    margin-left: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

#whatsappBroadcastContainer .nm-template {
    height: calc(100vh - 140px);
}

.custom-label {
    font-size: 10px !important;
}

.role-card-disable {
    background-color: #eee !important;
}

.variable-card {
    border-left: 3px solid #2d6aa0;
    padding: 5px;
    margin-top: 10px;
    margin-right: 5px;
    width: 30%;
    box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 13px 7px 28px -6px rgba(0, 0, 0, 0.09);
}

#messageList p {
    overflow-wrap: break-word;
    white-space: break-spaces;
}

.whtsapp-left {
    width: fit-content;
    max-width: 70%;
    background-color: beige;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
}

.whtsapp-right {
    width: fit-content;
    max-width: 70%;
    background-color: bisque;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    padding: 0 0.5rem;
    float: right;
    align-self: flex-end;
    margin-bottom: 0.5rem;
    margin-right: 0.25rem;
}

.whtsapp-list {
    height: calc(100vh - 180px);
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    position: relative;
    /* display: inline-block; */
    justify-content: center;
    align-items: center;
    display: flex;
}

.input-wrapper input {
    padding-right: 40px;
}

.input-wrapper button {
    position: absolute;
    /* top: 0; */
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.msgNoRespond {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    float: right;
    margin-top: 0.25rem;
}

#whatsapp-suggestion {
    position: absolute;
    z-index: 1030;
    overflow: auto;
    bottom: 2.5rem;
    background: antiquewhite;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.5rem;
    width: 100%;
}

#whatsapp-suggestion>p {
    overflow-wrap: break-word;
    white-space: break-spaces;
    border-bottom: 1px solid;
    padding: 0.25rem;
}

#whatsapp-suggestion>p:last-child {
    border-bottom: none;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--primary);
    cursor: pointer;
    background-color: antiquewhite;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

@media only screen and (max-width: 766px) {
    .nm-container {
        width: 100%;
    }

    .nm-role-table {
        height: calc(100vh - 200px);
        margin-left: 10px !important;
    }

    .whtsapp-list {
        height: calc(100vh - 260px);
    }

    #whatsappBroadcastContainer .nm-template {
        height: calc(100vh - 150px);
    }

    .nm-template {
        height: calc(100vh - 270px);
    }

    .variable-card {
        width: 100%;
    }
}

/* custom scroll bar */

.nm-template::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.nm-template::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.nm-template::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}