.navbar-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px auto !important;
}
.navbar-company-logo-container {
    width: 80px;
}

.navbar-items-container > * {
    margin-left: 40px;
}
.navbar-items-user {
    width: 40px;
}
.navbar-items-link * {
    color: #5a5858;
    font-weight: 500;
}
.navbar-items-link:hover a {
    text-decoration: none !important;
}


/* mobile nav */
.navbar-mobile-items-container > * {
    margin-left: 20px;
}
.navbar-mobile-items-link * {
    color: #2d6aa0;
    font-size: 20px;
    font-weight: 500;
}
.navbar-mobile-items-link:hover a {
    text-decoration: none !important;
}


/* PROFILE MODAL */
.navbar-profile-modal-container {
    position: absolute;
    top: 63px;
    right: 10px;
    width: 280px;
    background-color: red;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;

    font-size: 12px;
    z-index: 99999;
}
@media screen and (max-width: 576px) {
    .navbar-profile-modal-container {
        width: calc(100% - 20px);
    }
}