.custProf-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
}

.custProf-main {
    height: calc(100vh - 170px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
}

@media only screen and (max-width: 766px) {
    .custProf-container {
        width: 100%;
    }

    .custProf-main {
        height: calc(100vh - 180px);
    }
}

.custom-link:hover {
    text-decoration: none;
}

.menu-list {
    overflow-x: auto;
    overflow-y: hidden;
}

.menu-list::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}

ul.profile {
    margin: 0;
    padding: 0;
    width: 100%;
    background: #fff;
    white-space: nowrap;
}

ul.profile li {
    display: inline-block;
}

.active-custProf {
    color: #2d6aa0;
    border-bottom: 2px solid #2d6aa0;
}

li>a {
    display: block;
    color: #a8a8a8;
    text-align: center;
    margin-right: 20px;
    margin-top: 20px;
    text-decoration: none;
    font-weight: 500;
}

li>a:hover {
    color: #2d6aa0;
    text-decoration: none;
}

.docs-box-profile {
    height: 250px;
    width: 250px;
    border: 1px dashed #a8a8a8;
    text-align: center;
    margin-right: 10px;
    position: relative;
}

.docs-parent {
    display: flex;
    flex-direction: column;
}

/* open doc in new tab icon */

.kyc-openDoc-prof-container {
    position: absolute;
    left: 5px;
    top: 5px;
}

.kyc-openDoc-prof {
    background-color: #e1e1e1;
    padding: 8px;
    border-radius: 20px;
    margin-right: 5px;
}

.kyc-openDoc-prof:hover {
    background-color: #2d6aa0;
    color: #fff;
}

.cust-prof-doc-parent {
    overflow: auto;
}

/* custom scroll bar */

.custProf-main::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.custProf-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custProf-main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.cust-prof-doc-parent::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.cust-prof-doc-parent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.cust-prof-doc-parent::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

/* holdings and order details */

.sub-head {
    font-size: 10px;
}

.sub-desc {
    font-size: 11px;
    font-weight: bolder !important;
}

.holding-companyName {
    font-size: 14px;
    font-weight: 500;
    color: #2d6aa0;
}

.sup-pos {
    position: revert !important;
}

.buycheck {
    font-size: 10px !important;
    background-color: rgba(25, 210, 31, 0.3);
    padding: 2px 10px;
    border-radius: 3px;
    color: #19d21f;
    margin: 0 10px;
}

.sellcheck {
    font-size: 10px !important;
    background-color: rgba(255, 58, 58, 0.2);
    padding: 2px 10px;
    border-radius: 3px;
    color: #ff3a3a;
    margin: 0px 10px;
}

table td {
    padding: 0.5em !important;
    vertical-align: middle !important;
}

table th {
    color: #a8a8a8;
    font-size: 12px;
    font-weight: 500;
    padding: 0.2em !important;
}

table tr {
    font-size: 10px;
}