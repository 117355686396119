.animBtn {
    background-color: #2d6aa0;
    color: #fff;
    font-family: inherit;
    border: 0;
    border-radius: 4px;
    padding: 8px 30px;
    margin: 5px;
    font-size: 14px;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: max-content;
}

.gSignIn-Anim {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #009FE3;
    color: #009FE3;
    box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }

    16.667% {
        box-shadow: 9984px -10px 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }

    33.333% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }

    50% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px -10px 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }

    66.667% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }

    83.333% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px -10px 0 0 #009FE3;
    }

    100% {
        box-shadow: 9984px 0 0 0 #009FE3, 9999px 0 0 0 #009FE3, 10014px 0 0 0 #009FE3;
    }
}