.kyc-status-bar>* {
  margin-right: 40px;
}

.kyc-document-container {
  min-height: 300px;
}

.distKYCContainer {
  margin-top: 5rem !important;
}

.kyc-document-container>.kyc-document-display {
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
}

.kyc-container {
  display: flex;
  width: 100%;
  margin-top: 57px;
}

@media only screen and (max-width: 766px) {
  .kyc-container {
    width: 100%;
  }
}

.kyc-main {
  height: calc(100vh - 60px);
  overflow: auto
}

.text-theme {
  color: #2d6aa0;
  font-weight: 500;
}

/* custom scroll bar */

.kyc-main::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

.kyc-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.kyc-main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.MuiDataGrid-window::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

.MuiDataGrid-window::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.kyc-document-pcontainer::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em;
}

.kyc-document-pcontainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.kyc-document-pcontainer::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.kyc-table {
  height: calc(90vh - 100px);
  width: 95%;
  margin: 2%
}

/* kyc open doc in new tab icon */

.kyc-openDoc-container {
  position: absolute;
  top: 10px;
  left: 20px;
}

.kyc-openDoc {
  background-color: #e1e1e1;
  padding: 8px;
  border-radius: 20px;
  margin-right: 5px;
}

.kyc-openDoc:hover {
  background-color: #2d6aa0;
  color: #fff;
}

/* kyc document main parent container */

.kyc-document-pcontainer {
  height: calc(100vh - 210px);
  overflow: auto;
  width: 100%;
}