.smbo-container {
    display: flex;
    width: 100%;
    margin-top: 57px;
    height: calc(100vh - 65px);
}

.buy-card {
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
}

/* add utr button modal */

.custom-button {
    border: none;
    background: none;
}

.custom-button:hover {
    cursor: pointer;
}

.utr-card {
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 7px;
}

/* custom data grid height and scroll */

.MuiDataGrid-root .MuiDataGrid-footerContainer {
    min-height: 0px !important;
    height: 30px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    display: flex !important;
    align-items: center !important;
    height: fit-content !important;
    white-space: normal !important;
    line-height: normal !important;
    vertical-align: middle !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    line-height: normal !important;
}

/* ---------------------------manual order punch-------------------------------------------------- */

.smPO-container {
    width: 100%;
    margin-top: 57px;
    height: calc(100vh - 65px);
}

.smPOI-container {
    margin: 20px;
    height: calc(100vh - 145px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    padding-left: 10px;
}

@media only screen and (max-width: 766px) {
    .smPO-container {
        width: 100%;
    }
}

.MuiInput-formControl {
    margin-top: 0px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    font-size: 12px !important;
}

.dropDown {
    height: auto !important;
}

/* custom scrollbar */

.smPOI-container::-webkit-scrollbar {
    width: 0.7em;
    height: 0.7em;
}

.smPOI-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.smPOI-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.orderInsightNotiDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.orderInsightNotiDiv div {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
}