.login-screen-form-wrapper {
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 10px;
}

.login-screen-wallpaper {
    height: 100%;
}

.login-screen-wallpaper>img {
    object-fit: cover;
}

.login-screen-logo {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 150px;
}

.login-screen-input {
    padding-left: 0;
    margin-bottom: 30px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #E1E1E1;
    border-radius: 0;
}

.login-screen-input:focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.login-screen-hr {
    margin: 0% !important;
}

.login-screen-hr-or {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
}

.login-screen-hr-or>p {
    position: absolute;
    width: 80px;
    padding: 0;
    margin: 0;
    top: -10px;
    background-color: white;
}

.google-sign-in-button {
    background-color: #f2f2f2;
    padding: 8px 30px;
    border-radius: 30px;
    white-space: normal !important;
    word-wrap: break-word;
}

.google-sign-in-button>img {
    width: 20px;
    margin-bottom: 3px;
    margin-right: 5px;
}

@media screen and (max-width: 576px) {
    .login-screen-sign-in-button {
        width: 100%;
        margin: auto;
        padding: 10px 0px;
        margin: 0px 0px 20px 0px;
        border-radius: 30px !important;
    }
}

.resetPass {
    width: 50%;
    margin-left: 1rem;
    margin-top: 57px;
}

@media screen and (max-width:992px) {
    .resetPass {
        width: 90%;
    }
}